// 提供解决方案的API函数
import request from '/utils/request'

export const toProductAdvSolveDetailPrefix = '/productadv/detail/';
/**
 * 获取解决方案列表
 * @param {Object} params - 可参考接口文档
 */
export const findProductAdvSolveList = (params) => {
  params.appId = 10002
  return request('/front/productadv/productadv/lists?url=&appId=10002', 'post', params)
}
